<template>
<span>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 344 324" :width="width" :height="height" :style="{top: topPx, left: leftPx}">
  <defs>
    <linearGradient :id="`${id}-gg`" x1="62.497" x2="23.212" y1="159.79" y2="146.22" gradientUnits="userSpaceOnUse">
    <stop :stop-color="fill1" offset="0"/>
    <stop :stop-color="fill2" offset="1"/>
    </linearGradient>
    <linearGradient :id="`${id}-pumpkin`" x1="90.989" x2="42.854" y1="198.29" y2="198.02" gradientUnits="userSpaceOnUse">
    <stop :stop-color="fill1" offset="0"/>
    <stop :stop-color="fill2" offset="1"/>
    </linearGradient>
  </defs>
  <path d="m139.12 78.122s-40.133-7.8351-58.832-1.8921c-16.369 5.2023-31.757 16.669-41.094 31.084-12.857 19.85-15.322 45.499-15.922 69.142-0.50694 19.979 1.9318 40.966 10.474 59.033 7.2477 15.329 33.069 38.653 33.069 38.653s-13.834-28.642-17.626-43.97c-4.3932-17.757-6.3398-36.3-5.6868-54.581 0.62843-17.594 1.8232-36.024 9.207-52.005 5.9896-12.964 15.063-25.642 27.283-33.031 17.235-10.421 59.128-12.434 59.128-12.434z" :fill="`url(#${id}-gg)`" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m139.12 78.122s-14.082 14.627-19.547 23.108c-6.7825 10.525-12.347 21.994-16.094 33.942-4.4636 14.233-6.5921 29.245-7.3509 44.142-0.89367 17.547-0.53675 35.466 3.3312 52.605 4.5216 20.035 22.894 57.206 22.894 57.206s-36.941 9.9956-55.539-14.982c-12.391-16.641-15.562-30.065-19.473-46.366-4.0704-16.961-4.7271-34.766-3.8398-52.185 0.89548-17.582 1.8232-36.024 9.207-52.005 5.9896-12.964 15.063-25.642 27.283-33.031 17.235-10.421 59.128-12.434 59.128-12.434z" :fill="`url(#${id}-pumpkin)`" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m139.12 78.122s-14.082 14.627-19.547 23.108c-6.7825 10.525-12.347 21.994-16.094 33.942-4.4636 14.233-6.5921 29.245-7.3509 44.142-0.89367 17.547-0.40834 35.437 3.3312 52.605 4.4002 20.2 22.18 57.92 22.18 57.92s17.722 6.7903 34.73 8.5806 33.302-1.4194 33.302-1.4194 5.9497-22.471 7.3736-33.97c1.8271-14.756 1.4027-29.713 1.456-44.581 0.0707-19.722-1.9495-39.431-1.5072-59.148 0.61371-27.364-11.288-83.745-11.288-83.745s-15.523-1.9957-23.293-1.574c-7.8745 0.42736-23.293 4.1403-23.293 4.1403z" :fill="`url(#${id}-pumpkin)`" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m239.13 101.86c7.5341 7.9704 13.144 17.737 17.829 27.654 5.4528 11.541 9.6837 23.86 11.685 36.467 2.4394 15.364 1.1399 31.139 0.22529 46.668-0.59989 10.186-1.6349 20.397-3.7398 30.381-1.6933 8.0318-3.1309 16.398-7.1144 23.575-5.1079 9.2029-9.7557 15.547-20.828 23.733-20.861 15.424-47.51 6.6618-47.51 6.6618s5.9497-22.471 7.3736-33.97c1.8271-14.756 1.4027-29.713 1.456-44.581 0.0707-19.722-1.9495-39.431-1.5072-59.148 0.61371-27.364-11.288-83.745-11.288-83.745s18.047 2.705 26.204 6.5072c10.168 4.7392 19.508 11.645 27.214 19.798z" :fill="`url(#${id}-pumpkin)`" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m239.13 101.86c7.5341 7.9704 13.144 17.737 17.829 27.654 5.4528 11.541 9.6089 23.872 11.685 36.467 1.8917 11.474 1.0532 23.263 0.58243 34.882-0.56687 13.992-0.97103 28.16-4.097 41.81-1.8409 8.0385-4.3636 16.109-8.5429 23.218-5.7657 9.8077-22.257 25.876-22.257 25.876s22.146 0.54195 37.118-13.736c8.1274-7.75 17.482-18.822 22.822-30.276 8.513-18.26 14.232-38.76 13.578-58.896-0.93336-28.754-7.111-60.672-24.802-82.667-31.554-39.23-97.335-30.636-97.335-30.636s18.047 2.705 26.204 6.5072c10.168 4.7392 19.508 11.645 27.214 19.798z" :fill="`url(#${id}-pumpkin)`" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m281.99 95.432c10.641 8.0248 18.933 19.344 24.972 31.225 7.9456 15.631 12.34 33.282 13.828 50.753 1.0932 12.831-0.92618 25.879-3.7033 38.454-3.5133 15.908-7.2567 32.505-16.24 46.096-7.2915 11.031-17.136 21.505-29.257 26.789-11.421 4.9793-37.257 3.0186-37.257 3.0186s22.146 0.54195 37.118-13.736c8.1274-7.75 17.482-18.822 22.822-30.276 8.513-18.26 14.232-38.76 13.578-58.896-0.93336-28.754-7.111-60.672-24.802-82.667-31.554-39.23-97.335-30.636-97.335-30.636s36.455-1.6896 54.061 2.2215c14.89 3.3076 30.037 8.4709 42.214 17.655z" :fill="`url(#${id}-pumpkin)`" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m220.1 41.037c-3.8807 2.583-9.096 2.5587-13.05 5.0276-4.099 2.5592-7.7948 5.9469-10.622 9.8659-1.8769 2.6017-4.124 5.5524-3.9911 8.7577 0.12234 2.9508 1.7775 6.2432 4.329 7.7306 3.2535 1.8965 7.5536-0.45958 11.298-0.05406 3.2345 0.35033 8.494-0.95077 9.5238 2.1354 1.1469 3.437-3.8649 6.5496-7.0531 8.2711-5.6997 3.0777-12.806 2.2276-19.28 2.4327-8.8396 0.28-17.686-0.71244-26.481-1.6488-7.2559-0.77258-14.495-1.8134-21.645-3.2706-5.6623-1.154-16.788-4.3248-16.788-4.3248s4.5512-4.4016 7.4332-5.4871c7.1927-2.709 15.862 0.96488 22.975-1.9462 5.1205-2.0955 9.1517-6.4153 12.776-10.596 4.4102-5.087 6.2922-12.01 10.559-17.218 3.6336-4.4358 7.6591-8.8059 12.607-11.704 5.379-3.1506 11.699-4.4594 17.802-5.7303 3.2064-0.66771 9.7772-0.97308 9.7772-0.97308l4.7513 13.596s-2.9467 3.8221-4.9203 5.1357z" :fill="fill1" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m143.12 112.27s2.053 18.08 3.2253 27.102c1.2503 9.6215 4.0615 28.822 4.0615 28.822s-15.715 1.5158-23.264 3.6697c-5.7979 1.6542-16.664 7.0336-16.664 7.0336s-7.7684-21.703 3.4344-42.316c10.45-19.228 29.207-24.312 29.207-24.312z" :fill="fill2" opacity=".6" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m143.12 112.27 1.205 7.4043s-17.705 7.8936-25.233 22.762c-5.5125 10.888-2.556 33.974-2.556 33.974l-6.0576 2.4879s-7.7684-21.703 3.4344-42.316c10.45-19.228 29.207-24.312 29.207-24.312z" :fill="fill1" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path transform="matrix(-1 0 0 1 372.92 -2.0203)" d="m143.12 112.27s2.053 18.08 3.2253 27.102c1.2503 9.6215 4.0615 28.822 4.0615 28.822s-15.715 1.5158-23.264 3.6697c-5.7979 1.6542-16.664 7.0336-16.664 7.0336s-7.7684-21.703 3.4344-42.316c10.45-19.228 29.207-24.312 29.207-24.312z" :fill="fill2" opacity=".6" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m229.8 110.25-1.205 7.4043s17.705 7.8936 25.233 22.762c5.5125 10.888 2.556 33.974 2.556 33.974l6.0576 2.4879s7.7684-21.703-3.4344-42.316c-10.45-19.228-29.207-24.312-29.207-24.312z" :fill="fill1" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m74.345 187.23s3.5186 30.854 10.154 44.572c5.6665 11.715 24.459 30.428 24.459 30.428s13.092 5.3249 19.8 7.5305c6.9408 2.2822 21.114 5.8868 21.114 5.8868l6.152-20.948 17.411 3.2874 0.26878 20.374 21.861-2.2553s17.371 2.7255 25.803 0.99388c9.7706-2.0067 27.027-12.844 27.027-12.844l0.47782-17.699 10.781-3.7079 3.4941 8.6391s17.762-16.206 23.025-26.72c6.0039-11.994 8.6606-39.296 8.6606-39.296s-8.1715 6.7796-12.632 9.633c-3.9941 2.5547-12.573 6.6513-12.573 6.6513s-7.339 10.321-12.274 14.144c-5.2663 4.0788-17.889 8.9067-17.889 8.9067l-7.1375 10.78-0.77646 12.003-14.783 3.2492-4.3303-12.271-14.036-1.4144s-5.8755-1.9812-8.9294-2.0642c-7.7781-0.21152-15.217 3.83-22.995 4.0137-6.51 0.15381-19.382-2.4465-19.382-2.4465l-1.0751 11.315-15.44-0.76452-5.0769-21.904s-9.6235-6.5052-14.185-10.092c-5.2611-4.1361-15.171-13.15-15.171-13.15s-6.6992-1.473-9.5864-3.211c-4.8147-2.8982-12.214-11.621-12.214-11.621z" :fill="fill2" opacity=".6" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m74.345 187.23s3.5186 30.854 10.154 44.572c5.6665 11.715 24.459 30.428 24.459 30.428s13.092 5.3249 19.8 7.5305c6.9408 2.2822 21.114 5.8868 21.114 5.8868l6.152-20.948 17.411 3.2874 0.26878 20.374 21.861-2.2553s17.371 2.7255 25.803 0.99388c9.7706-2.0067 27.027-12.844 27.027-12.844l0.47782-17.699 10.781-3.7079 3.4941 8.6391s17.762-16.206 23.025-26.72c6.0039-11.994 8.6606-39.296 8.6606-39.296l-5.0564 3.572s-0.90982 16.888-3.5427 24.819c-2.4138 7.2713-6.5272 13.962-11.004 20.179-3.8785 5.3865-13.556 14.586-13.556 14.586l-1.4137-5.7716-5.2039-5.7547-12.393 3.9218-0.64059 21.937s-13.731 8.5434-21.503 9.9528c-8.4372 1.5301-25.653-1.9194-25.653-1.9194l-20.546 3.4916-0.77199-19.725-18.372-3.4566-6.1259 20.406s-13.685-2.2359-20.133-4.6572c-7.6552-2.8743-21.632-11.567-21.632-11.567s-9.7008-11.24-13.558-17.545c-4.3734-7.1492-8.2504-14.735-10.712-22.746-2.2204-7.2264-3.5254-22.404-3.5254-22.404l-5.1433-5.5598z" :fill="fill1" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m184.99 155.08s-10.856 21.503-17.47 31.536c-5.1397 7.796-17.172 22.133-17.172 22.133s16.974 4.1499 25.623 4.0902c7.3656-0.0509 21.771-3.7844 21.771-3.7844s9.7002 1.2667 14.514 0.72629c3.8428-0.43143 11.169-3.1345 11.169-3.1345s-7.4807-11.538-11.901-16.781c-4.257-5.0494-13.991-14.029-13.991-14.029l-12.543-20.757z" :fill="fill2" opacity=".6" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="m174.38 206.6c-6.6191-0.1347-19.491-3.8189-19.491-3.8189l-4.5449 5.9704s16.974 4.1499 25.623 4.0902c7.3656-0.0509 21.771-3.7844 21.771-3.7844s9.7002 1.2667 14.514 0.72629c3.8428-0.43143 11.169-3.1345 11.169-3.1345l-3.3145-5.1645s-3.1379-6.1099-8.8011-12.725-13.852-13.735-13.852-13.735l19.988 29.702-20.418-1.6132s-15.009 3.6423-22.644 3.4869z" :fill="fill1" :stroke="stroke1" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</span>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'pumpkin',

  mixins: [ThemeMixin]
}
</script>
